:root {
  --primaryColor: #faf9f8;
  --primaryWhite: #ffffff;
  --secColor: #8F7054;
  --secColor2: #9e5f44;
  --secColor3: #b8a490;
  --terColor: #FCF1EB;
  --terColor2: #ad9486;
  --accColor: #54738F;
  --accColor2: #6D8DA9;
  --appBg: #faf9f8;
}

html {
  background-color: var(--appBg) !important;
}

body {
  font-family: 'Hedvig Letters Serif', serif !important;
  color: var(--secColor) !important;
}

.fullPage {
  margin-top: 2%;
  min-height: 80vh;
  min-width: 100vw;
}

.App {
  background-color: var(--appBg) !important;
  font-family: 'Hedvig Letters Serif', serif !important;
  color: var(--secColor) !important;
  min-height: 100vh;
}

a {
  color: var(--appBg) !important;
}

.a2 {
  color: var(--secColor) !important;
}

.MuiTypography-root {
  font-family: 'Hedvig Letters Serif', serif !important;
}

.Mui-selected {
  background: var(--secColor) !important;
  color: var(--appBg) !important;
}

.react-file-reader-button {
  width: 90vw;
}

.audio-recorder {
  background-color: var(--secColor) !important;
  color: var(--appBg) !important;
}

.audio-recorder-mic {
  color: var(--appBg) !important;
}

.ant-tour {
  max-width: 90%;
  font-family: 'Hedvig Letters Serif', serif !important;
}

.ant-btn {
  font-family: 'Hedvig Letters Serif', serif !important;
}

.ant-btn-default {
  color: var(--secColor) !important;
  border-color: var(--secColor) !important;
}

.ant-btn-primary {
  background-color: var(--secColor) !important;
}

.ant-tour-indicator-active {
  background-color: var(--secColor) !important;
}